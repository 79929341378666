import React from "react"
import Layout from "../components/layout";
import globalStyle from "../styles/global.module.css";
import {Col, Row, Container} from "react-bootstrap";
import {graphql} from "gatsby";
import Img from "gatsby-image";
import pressStyle from "./press.module.css";

const PressPage =  ({data}) => {
  return (
    <Layout className="site-content">
      <Container>
        <Row>
          <Col>
            <div>
              <h1 className={globalStyle.entryTitle}>MOM in the press</h1>
            </div>

            <Row className={pressStyle.articleStyle}>
              <Col md={3} sm={12} xs={12}>
                <a href="http://www.sdnews.com/view/full_story/27424101/article--Moms-on-Maternity-support-group-starts-in-Ocean-Beach-"
                target="_blank">
                  <Img className={pressStyle.articleImage} fluid={data.momInThePressSdnews.childImageSharp.fluid}/>
                </a>

              </Col>
              <Col md={9} sm={12} xs={12}>
                <a className={pressStyle.articleTitle}
                   target="_blank"
                   href="http://www.sdnews.com/view/full_story/27424101/article--Moms-on-Maternity-support-group-starts-in-Ocean-Beach-">
                  <h4 className={pressStyle.articleTitle}>Moms on Maternity support group starts in Ocean Beach</h4></a>
                <p className={pressStyle.articleDetails}>By <span>DAVE SCHWAB</span><span>Published - 05/29/17 - 12:17 PM</span></p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
};

export const query = graphql`
  query {
    momInThePressSdnews: file(relativePath: { eq: "press/mom-in-the-press-sdnews.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    momInThePressCBS: allVimeo(
      filter: {tags: {elemMatch: {tag: {eq: "mom-in-the-press-cbs"}}}}
    ) {
      edges {
        node {
          id
          name
          link
          tags {
            name
          }
          embed {
            html
          }
        }    
      }
    }
    momInThePressFOX: allVimeo(
      filter: {tags: {elemMatch: {tag: {eq: "mom-in-the-press-fox"}}}}
    ) {
      edges {
        node {
          id
          name
          link
          tags {
            name
          }
          embed {
            html
          }
        }    
      }
    }
    momInThePressKusi: allVimeo(
      filter: {tags: {elemMatch: {tag: {eq: "mom-in-the-press-kusi"}}}}
    ) {
      edges {
        node {
          id
          name
          link
          tags {
            name
          }
          embed {
            html
          }
        }    
      }
    }
  }
`;

export default PressPage
